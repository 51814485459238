import SelectableIcon from "@components/Layout/icons/selectable-icon";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import useSelectedColumns from "@lib/hooks/use-selected-columns";
import {
  Modal,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@ui";
import { CardContainer } from "@components/Cards/CardContainer";
import {
  COLUMN_OPTIONS,
  GENERAL_COLUMN_OPTIONS,
  maxMetricCountForTables,
} from "constants/constants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { toast } from "sonner";
import { metricsInformation } from "@components/MetricNameWithTooltip/metricsInformation";
import { dashboardModeAtom, newVsReturningAtom } from "atoms";
import { useAtom } from "jotai";
import { Button } from "@components/ui/button";

export type TableTypes =
  | "realtime"
  | "marketingChannelOverview"
  | "creativesOverview"
  | "pinterestCreativesOverview"
  | "facebookCreativesOverview"
  | "googleChannels"
  | "adsManager"
  | "pinterestAdsManager"
  | "facebookAdsManager"
  | "influencer"
  | "cooperations"
  | "influencerChannelOverview"
  | "googleAdsManager"
  | "creativeComparison"
  | "facebookCreativeComparison"
  | "creativeAnalysis"
  | "facebookCreativeAnalysis";

type Props = {
  open: boolean;
  onClose: (value?: boolean) => void;
  table: TableTypes;
  dontShowColumn?: string;
};

export interface ColumnItemType {
  value:
    | (typeof COLUMN_OPTIONS)[number]["value"]
    | (typeof GENERAL_COLUMN_OPTIONS)[number]["value"];
  label:
    | (typeof COLUMN_OPTIONS)[number]["label"]
    | (typeof GENERAL_COLUMN_OPTIONS)[number]["label"];
  id:
    | (typeof COLUMN_OPTIONS)[number]["value"]
    | (typeof GENERAL_COLUMN_OPTIONS)[number]["value"];
}

const ColumnSelectorDialog: React.FC<Props> = ({
  open,
  onClose,
  table,
  dontShowColumn = "",
}) => {
  const { selectedColumnsOrder, additionalMetrics, onSetNewColumnOrder } =
    useSelectedColumns({ table });

  const [newVsReturning] = useAtom(newVsReturningAtom);
  const [dashboardMode] = useAtom(dashboardModeAtom);

  const [tempSelectedColumns, setTempSelectedColumns] =
    useState(selectedColumnsOrder);

  const [tempAdditionalMetrics, setTempAdditionalMetrics] =
    useState(additionalMetrics);

  useEffect(() => {
    setTempAdditionalMetrics(additionalMetrics);
  }, [additionalMetrics]);
  useEffect(() => {
    setTempSelectedColumns(selectedColumnsOrder);
  }, [selectedColumnsOrder]);

  const roasByDashboardMode = useMemo(() => {
    const prefix = newVsReturning === "new" ? "Acquisition " : "";
    return `${prefix}ROAS`;
  }, [newVsReturning]);

  const overwriteLabels: Record<string, Record<string, string>> = {
    marketingChannelOverview: {
      roas: roasByDashboardMode,
    },
    googleChannels: {
      roas: roasByDashboardMode,
    },
    googleAdsManager: {
      roas: roasByDashboardMode,
    },
    facebookAdsManager: {
      roas: roasByDashboardMode,
      ctr: "CTR (link)",
    },
    adsManager: {
      roas: roasByDashboardMode,
    },
    influencer: {
      roas: roasByDashboardMode,
    },
    cooperations: {
      roas: roasByDashboardMode,
    },
    influencerChannelOverview: {
      roas: roasByDashboardMode,
    },
    facebookCreativesOverview: {
      ctr: "CTR (link)",
      roas: roasByDashboardMode,
    },
    creativesOverview: {
      roas: roasByDashboardMode,
    },
    facebookCreativeComparison: {
      ctr: "CTR (link)",
      roas: roasByDashboardMode,
    },
    pinterestAdsManager: {
      roas: roasByDashboardMode,
    },
    pinterestCreativesOverview: {
      roas: roasByDashboardMode,
    },
    facebookCreativeAnalysis: {
      ctr: "CTR (link)",
      roas: roasByDashboardMode,
    },
  };

  const overwriteSettings = overwriteLabels[table] ?? {};

  const title = (
    <div className="flex items-center py-2 space-x-6">
      <span>Metrics Setup</span>
    </div>
  );

  const handleSetTempSelectedColumns = useCallback(
    (val: ColumnItemType[]) => {
      if (
        maxMetricCountForTables[table] &&
        val.length > maxMetricCountForTables[table]
      ) {
        toast.error(
          `You can select max. ${maxMetricCountForTables[table]} metrics at same time.`,
          { id: "max_columns_selected_error" }
        );
        return;
      }
      setTempSelectedColumns(val);
    },
    [table]
  );
  const handleSetTempAdditionalColumns = useCallback(
    (val: ColumnItemType[]) => {
      if (
        maxMetricCountForTables[table] &&
        tempSelectedColumns.length > maxMetricCountForTables[table]
      ) {
        return;
      }
      setTempAdditionalMetrics(val);
    },
    [table, tempSelectedColumns.length]
  );

  const moveToSelectedColumns = useCallback(
    (item: ColumnItemType) => {
      if (
        maxMetricCountForTables[table] &&
        tempSelectedColumns.length >= maxMetricCountForTables[table]
        // >= is important here, because tempSelectedColumns is not updated yet and could be exactly the max allowed number
      ) {
        toast.error(
          `You can select max. ${maxMetricCountForTables[table]} metrics at same time.`,
          { id: "max_columns_selected_error" }
        );
        return;
      }
      setTempAdditionalMetrics((current) => {
        const index = current.findIndex((el) => el.id === item.id);
        if (index !== -1) {
          const newState = [
            ...current.slice(0, index),
            ...current.slice(index + 1),
          ];
          return newState;
        }
        return current;
      });
      setTempSelectedColumns((current) => [
        ...current,
        { value: item.value, id: item.id, label: item.label },
      ]);
    },
    [table, tempSelectedColumns.length]
  );

  const moveToAdditionalMetrics = useCallback(
    (item: ColumnItemType) => {
      setTempSelectedColumns((current) => {
        const index = current.findIndex((el) => el.id === item.id);
        if (index !== -1) {
          const newState = [
            ...current.slice(0, index),
            ...current.slice(index + 1),
          ];
          return newState;
        }
        return current;
      });
      setTempAdditionalMetrics((current) => [
        ...current,
        { value: item.value, id: item.id, label: item.label },
      ]);
    },
    [setTempAdditionalMetrics]
  );

  const footer = (
    <div className="sticky bottom-0 inset-x-0 px-6 py-4 flex bg-gray-200 dark:bg-gray-700 justify-end w-full">
      <Button
        size="sm"
        variant="primary"
        onClick={() => {
          onSetNewColumnOrder(tempSelectedColumns);
          onClose();
        }}
      >
        Apply
      </Button>
    </div>
  );

  return (
    <Modal
      onClose={onClose}
      open={open}
      title={title}
      footer={footer}
      disableBackgroundClick
    >
      <>
        <div>
          <h5 className="h6">Selected Metrics</h5>
          {maxMetricCountForTables[table] ? (
            <p className="text-foreground-soft">
              You can select max. {maxMetricCountForTables[table]} metrics at
              the same time
            </p>
          ) : null}
          <CardContainer margin="my-3">
            <ReactSortable
              list={tempSelectedColumns.filter(
                (item) => item.value !== dontShowColumn
              )}
              setList={handleSetTempSelectedColumns}
              className="space-y-2"
              handle=".handle"
              selectedClass="bg-primary"
              // ghostClass="bg-primary"
              group="groupName"
              animation={200}
              delayOnTouchOnly={true}
              delay={2}
            >
              {tempSelectedColumns.map((item, index) => (
                <div key={`${item.id}:${index}`}>
                  <TooltipProvider>
                    <Tooltip
                      open={metricsInformation[item.value] ? undefined : false}
                    >
                      <TooltipTrigger asChild>
                        <div className="bg-gray-100 dark:bg-gray-800 py-2 px-1.5 rounded-xl flex items-center justify-between">
                          <div className="flex items-center">
                            <button
                              onClick={() => moveToAdditionalMetrics(item)}
                              className="p-1"
                            >
                              <MinusIcon className="text-primary h-4 w-4" />
                            </button>
                            <p className="ml-2 text-sm">
                              {overwriteSettings[item.value] ?? item.label}
                            </p>
                          </div>
                          <button className="handle p-1 cursor-move">
                            <SelectableIcon />
                          </button>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent side="right" className="max-w-xs">
                        {metricsInformation[item.value]}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              ))}
            </ReactSortable>
          </CardContainer>
          {tempAdditionalMetrics.length > 0 ? (
            <>
              <h5 className="h6 mt-4">Additional Metrics</h5>
              <CardContainer margin="my-3">
                <ReactSortable
                  list={tempAdditionalMetrics.filter(
                    (item) => item.value !== dontShowColumn
                  )}
                  setList={handleSetTempAdditionalColumns}
                  className="space-y-2"
                  handle=".handle"
                  selectedClass="bg-primary"
                  // ghostClass="bg-primary"
                  group="groupName"
                  animation={200}
                  delayOnTouchOnly={true}
                  delay={2}
                >
                  {tempAdditionalMetrics.map((item, index) => (
                    <div key={item.id + index}>
                      <TooltipProvider>
                        <Tooltip
                          open={
                            metricsInformation[item.value] ? undefined : false
                          }
                        >
                          <TooltipTrigger asChild>
                            <div className="bg-gray-100 dark:bg-gray-800 py-2 px-1.5 rounded-lg flex items-center justify-between">
                              <div className="flex items-center">
                                <button
                                  onClick={() => moveToSelectedColumns(item)}
                                  className="p-1"
                                >
                                  <PlusIcon className="text-greenRect h-4 w-4" />
                                </button>
                                <p className="ml-2 text-sm">
                                  {overwriteSettings[item.value] ?? item.label}
                                </p>
                              </div>
                              <button className="handle p-1 cursor-move">
                                <SelectableIcon />
                              </button>
                            </div>
                          </TooltipTrigger>
                          <TooltipContent side="right" className="max-w-xs">
                            {metricsInformation[item.value]}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  ))}
                </ReactSortable>
              </CardContainer>
            </>
          ) : null}
        </div>
      </>
    </Modal>
  );
};

export default ColumnSelectorDialog;
